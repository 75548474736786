import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {Box, Container, Skeleton, Stack, styled, Typography} from "@mui/material";

const Logo = styled("img")(({theme}) => ({
    [theme.breakpoints.up('xs')]: {
        width: "200px",
    },
    [theme.breakpoints.up('sm')]: {
        width: "300px",
    },
    [theme.breakpoints.up('md')]: {
        width: "400px",
    },
    [theme.breakpoints.up('lg')]: {
        width: "400px",
    },
}));
const About = () => {
    return (
        <Stack justifyContent="center" alignItems="center" textAlign="center"
               sx={{color: "black", minHeight: "83vh"}} mt={{xs: 5, md: 0}}>
            <Container>
                <Grid container  alignItems="center">
                    <Grid xs={12} md={6}>
                        <Box textAlign="center">
                        <Logo src={`${process.env.PUBLIC_URL}/img/logo_bleu_500x500.png`} alt="logo"/>
                        </Box>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Typography paragraph variant="h5">A propos :</Typography>
                        <Typography paragraph textAlign="justify" variant="body1">
                            Tutotou a été créé pour répondre à un besoin que j'ai moi-même rencontré en cherchant des
                            tutoriels sur les loisirs créatifs et DIY. Malgré de nombreux blogs et chaînes YouTube
                            dédiées à ces sujets, il n'y avait pas de site regroupant tous les tutoriels de manière
                            claire et facile à suivre, étape par étape. C'est ainsi que Tutotou est né : une plateforme
                            en ligne où les utilisateurs peuvent trouver et partager des tutoriels de toutes sortes,
                            dans un format étape par étape facile à suivre. Nous sommes convaincus que tout le monde
                            peut apprendre à créer de belles choses et nous voulons offrir un outil pour que cela
                            devienne accessible à tous. Que vous soyez débutant ou expert, nous espérons que Tutotou
                            vous aidera à développer votre créativité et à réaliser vos projets les plus ambitieux.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Stack>
    );
};

export default About;
