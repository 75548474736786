import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {Button, Container, Divider, Link, Stack, Typography} from "@mui/material";

const MarketResearch = () => {
    return (
        <Stack justifyContent="center" alignItems="center" textAlign="center" spacing={{xs: 5, md: 1}}
               sx={{color: "black", minHeight: "83vh"}} mt={{xs: 5, md: 0}}>
            <Container>
                <Grid spacing={10} container justifyContent="center" alignItems="center" mb={{sx: 10}}>
                    <Grid xs={12} md={6} justifyContent="center" spacing={15}>
                        <Typography variant="h4">Votre avis compte !</Typography>
                        <br/>
                        <Typography paragraph textAlign="justify" variant="subtitle1">Nous
                            souhaitons créer une
                            plateforme qui répond parfaitement aux besoins des amateurs de DIY et loisirs créatifs.
                            Pour cela, nous
                            avons besoin de connaître vos préférences et vos attentes. En remplissant notre
                            formulaire d'étude de
                            marché, vous nous aiderez à mieux comprendre vos besoins et à adapter notre plateforme
                            pour répondre à
                            vos attentes. Votre participation est donc essentielle pour nous aider à développer la
                            plateforme de vos
                            rêves.<br/><br/>N'hésitez plus, votre avis est précieux pour nous !
                        </Typography>
                    </Grid>
                    <Grid xs={12} md={6} textAlign="center">
                        <Link href="https://forms.gle/UF5KHca4hDQ6tSHLA"><Button variant="contained" sx={{p:5}}>Répondre au
                            questionnaire</Button></Link>
                    </Grid>
                </Grid>
            </Container>
        </Stack>
    );
};

export default MarketResearch;
