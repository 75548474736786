import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {Alert, Button, Container, Link, Stack, Typography} from "@mui/material";

const Contact = () => {
    return (
        <Stack sx={{color: "black"}}>
            <Container>
                            <Alert color="warning">Bientôt ici un formulaire de contact !</Alert>
                <Grid container mt={20}>
                    <Grid xs={12}>
                        <Stack justifyContent="center" alignItems="center">
                            <Typography>a.obligis@sigilbo.fr</Typography>
                            <br/>
                            <Link href="mailto:a.obligis@sigilbo.fr"><Button variant="contained">Envoyer un
                                email</Button></Link>
                        </Stack>
                    </Grid>
                </Grid>
                {/* <Typography variant="h4"> Formulaire de contact</Typography>
                    <Box
                        component="form"
                        sx={{
                            m:15,
                            '& .MuiTextField-root': {m: 1, width: '25ch'},
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Stack alignItems="center">
                        <TextField id="outlined-basic" label="Nom" variant="outlined"/>
                        <TextField id="outlined-basic" label="Prénom" variant="outlined"/>
                        <TextField id="outlined-basic" label="Fonction" variant="outlined"/>
                        <TextField id="outlined-basic" label="Message" variant="outlined" multiline minRows={3}/>
                            <br/>
                            <br/>
                        <Button variant="contained" type="submit">Envoyer</Button>
                        </Stack>
                    </Box>*/}
            </Container>
        </Stack>
    );
};

export default Contact;
