import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {Box, Link, Stack, Typography} from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
    return (
        <Grid xs={12} sx={{height:'10vh'}}>
            <Stack justifyContent="center" height="100%">
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                    <Link href="https://www.facebook.com/tutotou.fr"><FacebookIcon sx={{color:"#9D62DE"}} fontSize="large"/></Link>
                    <Link href="https://www.linkedin.com/company/tutotou-fr"><LinkedInIcon sx={{color: "#9D62DE"}} fontSize="large"/></Link>
                    <Link href="https://www.instagram.com/tutotou_fr"><InstagramIcon sx={{color: "#9D62DE"}} fontSize="large"/></Link>
                    <Link href="https://twitter.com/Tutotou_fr"><TwitterIcon sx={{color: "#9D62DE"}} fontSize="large"/></Link>
                </Stack>
                <Stack direction="row" spacing={{xs:1, md:3}} alignItems="center" justifyContent="center">
                    <Link href="https://beta.tutotou.fr/cgu" underline="none"><Typography sx={{color: "#9D62DE"}} variant="body1">conditions générales d'utilisation</Typography></Link>
                    <Link href="https://beta.tutotou.fr/pc" underline="none"><Typography sx={{color: "#9D62DE"}} variant="subtitle1">politique de confidentialité</Typography></Link>
                </Stack>
            </Stack>
        </Grid>
    );
}

export default Footer;
