import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {Box, Card, CardContent, CardMedia, Container, Stack, Typography} from "@mui/material";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

const Feature = () => {
    return (
        <Stack sx={{color: "black", minHeight: "83vh"}}>
            <Container>
                <Grid container spacing={4} mt={{xs: 5, md: 1}}>
                    <Grid xs={12} md={8} mt={{xs: 1, md: 12}}>
                        <Grid container spacing={4} textAlign="center">
                            <Typography variant="h4" >Fonctionnalités des créateurs</Typography>
                            {creatorFeatures.map((feature, index) =>
                                <Grid key={index} xs={12} md={6} sx={{backgroundColor: ""}}>
                                    <Card key={index}
                                          sx={{
                                              borderColor: "#9d62de",
                                              borderRadius: 2, borderStyle: "solid", borderWidth: 2, height: 275
                                          }}>
                                        <CardContent>
                                            <Typography variant="h5">
                                                {feature.title}
                                            </Typography>
                                            <br/>
                                            <Typography textAlign="justify" variant="body2">
                                                {feature.description}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={4} mt={10}>
                        <Grid container spacing={4} justifyContent="center">
                            <Typography variant="h4">Time line</Typography>
                            <Timeline sx={{
                                color: "black",
                            }}>
                                {creatorTimeline.map((version, indexTimeLine) =>
                                    <Box key={indexTimeLine}>
                                        {version.items.map((item, index) =>
                                            <TimelineItem key={index}>
                                                <TimelineOppositeContent
                                                    align="right"
                                                    variant="body2"
                                                    color="text.secondary"
                                                >
                                                    <Typography>{version.name}</Typography>
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineDot color={item.status}/>
                                                    {(creatorTimeline.length !== indexTimeLine + 1 || version.items.length !== index + 1) &&
                                                        <TimelineConnector/>
                                                    }
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <Typography>{item.content}</Typography></TimelineContent>
                                            </TimelineItem>
                                        )}
                                    </Box>
                                )}
                            </Timeline>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Stack>
    );
};
export default Feature;

const creatorTimeline = [
    {
        name: 'v1',
        items: [
            {
                status: "warning",
                content: "Créer un tutoriel"
            },
            {
                status: "warning",
                content: "Ajouter du matériels"
            },
            {
                status: "success",
                content: "Ajouter ses propres liens d'affiliation"
            },
            {
                status: "error",
                content: "Gestion de ses tutoriels"
            }
        ]
    },
    {
        name: 'v2',
        items: [
            {
                status: "error",
                content: "Statistiques sur les tutoriels"
            },
            {
                status: "error",
                content: "Mise en avant dans la recherche"
            },
            {
                status: "error",
                content: "Intégration d'un tutoriel sur une autre plateforme via iframe"
            }]
    }
]

const creatorFeatures = [
    {
        title: "Ajouter ses propres liens d'affiliations",
        image: "",
        description: "Permet aux créateurs de contenu de générer des revenus supplémentaires en insérant leurs propres liens d'affiliation dans les tutoriels qu'ils créent. Ils peuvent ainsi monétiser leur expertise et leur influence, tout en proposant des produits ou services pertinents et de qualité à leur audience.",
        status: "online",
    },
    {
        title: "Voir les réalisations des utilisateurs",
        image: "",
        description: "Permet aux créateurs de contenu de découvrir les projets de la communauté réalisés grâce à leurs tutoriels. En explorant cette section, les créateurs peuvent voir les différentes variations et adaptations que les utilisateurs ont apportées à leurs créations. Cela permet également aux créateurs de voir comment leur contenu est reçu et apprécié.",
        status: "online",
    }
    ,
    {
        title: "Confort visuel pour la communauté",
        image: "",
        description: "Elle vous permet de présenter des tutoriels de manière claire et structurée pour que les abonnés puissent suivre facilement chaque étape de des créations. En créant des tutoriels sur Tutotou, vous pouvez ajouter des images et des textes pour chaque étape, ce qui rend le processus de création plus visuel et compréhensible.",
        status: "warning",
    }
    ,
    {
        title: "Personnalisation des conteneurs d'étapes",
        image: "",
        description: "Les tutoriels dispose de conteneurs d'étapes, qui contiennent des étapes. Grâce à cette personnalisation, le créateur de contenu peut choisir la disposition du contenu et le nombre d'étapes par conteneurs. La disposition d'une étape est elle aussi personnalisable",
        status: "error",
    }
]
