import React from 'react';
import {
    AppBar,
    Box,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem, Step, StepButton,
    Stepper,
    styled,
    Toolbar,
    Typography
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {useSwiper} from "swiper/react";

const Logo = styled("img")(({theme}) => ({
    transform: 'rotate(-90deg)',
    width: "35px",
    marginRight: 10,
    [theme.breakpoints.up('xs')]: {
        display: 'none'
    },
    [theme.breakpoints.up('sm')]: {},
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    },
    [theme.breakpoints.up('lg')]: {},
}));
const LogoMobile = styled("img")(({theme}) => ({
    transform: 'rotate(-90deg)',
    width: "35px",
    marginRight: 10,
    [theme.breakpoints.up('xs')]: {
        display: 'flex'
    },
    [theme.breakpoints.up('sm')]: {},
    [theme.breakpoints.up('md')]: {
        display: 'none'
    },
    [theme.breakpoints.up('lg')]: {},
}));
const pages = ["Accueil", "Fonctionnalités", "Actualités", "A propos", "Contact"];
const Header = ({activeStep, setActiveStep}) => {
    const swiper = useSwiper();
    const handleStep = (step) => {
        console.log(step)
        setActiveStep(step);
    };

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };


    return (
        <AppBar position="static" sx={{bgcolor: "#9d62de", height:"7vh"}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Logo src={`${process.env.PUBLIC_URL}/logo/logo_white.svg`} alt="logo"/>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: {xs: 'none', md: 'flex'},
                            fontFamily: 'roboto',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        TUTOTOU
                    </Typography>

                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            <Typography variant="h6">
                                Swiper vers la gauche pour voir les différentes pages.
                            </Typography>
                        </Menu>
                    </Box>
                    <LogoMobile src={`${process.env.PUBLIC_URL}/logo/logo_white.svg`} alt="logo"/>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: {xs: 'flex', md: 'none'},
                            flexGrow: 1,
                            fontFamily: 'roboto',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        TUTOTOU
                    </Typography>
                   {/* <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}, marginLeft:'20%'}}>
                        {pages.map((page, index) => (
                            <Button
                                key={page}
                                onClick={() => {handleCloseNavMenu();
                                    swiper.slideTo(index)}}
                                sx={{my: 2, color: 'white', display: 'block'}}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>*/}
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default Header;
