import React, {useEffect, useState} from "react";
import './App.css';
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import Header from "./components/Header";
import Main from "./components/Main";
import Feature from "./components/Feature";
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import MarketResearch from "./components/MarketResearch";
import Crowdfunding from "./components/Crowdfunding";
import Grid from '@mui/material/Unstable_Grid2';
import TabPanel from "./components/TabPanel";
import {Button, Container, MobileStepper, Stack, useTheme} from "@mui/material";
import {Swiper, SwiperSlide, useSwiper} from "swiper/react";
import {Keyboard, Navigation} from "swiper";
import FeatureCreator from "./components/FeatureCreator";

function App() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const [width, setWidth] = useState (window.innerWidth);

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <>
            <Header activeStep={activeStep} setActiveStep={setActiveStep}/>
            <Grid container >
                <Swiper
                    slidesPerView={1}
                    spaceBetween={1}
                    keyboard={{enabled: true}}
                    navigation={!isMobile}
                    centeredSlides={true}
                    mousewheel={true}
                    autoHeight={true}
                    modules={[Keyboard, Navigation]}
                    //onSlideChange={() => console.log('slide change')}
                    //onSwiper={(swiper) => }
                >
                    <SwiperSlide><Main/></SwiperSlide>
                    <SwiperSlide> <Feature/></SwiperSlide>
                    <SwiperSlide> <FeatureCreator/></SwiperSlide>
                    <SwiperSlide> <MarketResearch/></SwiperSlide>
                    <SwiperSlide> <About/></SwiperSlide>
                    <SwiperSlide style={{height: '83vh'}}> <Contact/></SwiperSlide>
                </Swiper>
                <Footer/>
            </Grid>
        </>
    );
}

export default App;
