import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {Box, Card, CardContent, CardMedia, Container, Stack, Typography} from "@mui/material";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

const Feature = () => {
    return (
        <Stack sx={{color: "black", minHeight: "83vh"}}>
            <Container>
                <Grid container spacing={4} mt={{xs: 5, md: 1}}>
                    <Grid xs={12} md={8} mt={{xs: 1, md: 12}}>
                        <Grid container spacing={4} textAlign="center">
                            <Typography variant="h4">Fonctionnalités des utilisateurs</Typography>
                            {userFeatures.map((feature, index) =>
                                <Grid key={index} xs={12} md={6} sx={{backgroundColor: ""}}>
                                    <Card key={index}
                                          sx={{
                                              borderColor:"#9d62de",
                                              borderRadius: 2, borderStyle: "solid", borderWidth: 2, height: 275
                                          }}>
                                        <CardContent>
                                            <Typography variant="h5">
                                                {feature.title}
                                            </Typography>
                                            <br/>
                                            <Typography textAlign="justify" variant="body2">
                                                {feature.description}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={4} mt={10}>
                        <Grid container spacing={4} justifyContent="center">
                            <Typography variant="h4">Time line</Typography>
                            <Timeline sx={{
                                color: "black",
                            }}>
                                {userTimeline.map((version, indexTimeLine) =>
                                    <Box key={indexTimeLine}>
                                        {version.items.map((item, index) =>
                                            <TimelineItem key={index}>
                                                <TimelineOppositeContent
                                                    align="right"
                                                    variant="body2"
                                                    color="text.secondary"
                                                >
                                                    <Typography>{version.name}</Typography>
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineDot color={item.status}/>
                                                    {(userTimeline.length !== indexTimeLine + 1 || version.items.length !== index + 1) &&
                                                        <TimelineConnector/>
                                                    }
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <Typography>{item.content}</Typography></TimelineContent>
                                            </TimelineItem>
                                        )}
                                    </Box>
                                )}
                            </Timeline>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Stack>
    );
};
export default Feature;

const userTimeline = [
    {
        name: 'v1',
        items: [
            {
                status: "success",
                content: "Visualiser un tutoriel"
            },
            {
                status: "warning",
                content: "Créer un compte et se connecter"
            },
            {
                status: "success",
                content: "Pouvoir laisser un avis"
            },
            {
                status: "warning",
                content: "Paramétrage du compte et du profil"
            }
        ]
    },
    {
        name: 'v2',
        items: [
            {
                status: "warning",
                content: "Partager ses créations"
            },
            {
                status: "error",
                content: "Sauvegarde en temps réel"
            },
            {
                status: "error",
                content: "Ajouter la liste de son matériel"
            }]
    }
]
const userFeatures = [
    {
        title: "Lecture horizontale",
        image: "",
        description: "Le mode \"étape par étape\" de Tutotou permet aux utilisateurs de suivre un tutoriel de manière progressive et détaillée. Chaque page présente une ou plusieurs étapes, et chaque page s'enchaîne horizontalement. À chaque étape, l'utilisateur peut lire les instructions, voir des images et même regarder des vidéos explicatives si le créateur en a ajouté.",
        status: "online",
    },
    {
        title: "Recherche personnalisée",
        image: "",
        description: "Tutotou permet aux utilisateurs de trouver facilement des tutoriels correspondant à leurs besoins spécifiques. Les utilisateurs peuvent filtrer les résultats de recherche par catégorie, niveau de difficulté, durée, et même par matériel nécessaire pour le projet. Cette fonctionnalité est particulièrement utile pour les utilisateurs qui recherchent des tutoriels spécifiques pour des projets précis.",
        status: "error",
    }
    ,
    {
        title: "Sauvegarde en temps réel",
        image: "",
        description: "La fonctionnalité de sauvegarde en temps réel permet aux utilisateurs de ne jamais perdre leur progression dans un tutoriel. Grâce à cette fonctionnalité, il est possible de quitter un tutoriel à tout moment et de le reprendre ultérieurement exactement où vous vous étiez arrêté. En somme, la sauvegarde en temps réel rend l'expérience de l'utilisateur de Tutotou beaucoup plus pratique et efficace.",
        status: "error",
    }
    ,
    {
        title: "Partager ses créations",
        image: "",
        description: "permet aux utilisateurs de partager facilement les photos de leurs réalisations avec la communauté Tutotou. Cela leur permet de montrer leur créativité, d'inspirer les autres et de recevoir des commentaires positifs sur leurs créations. En partageant leurs réalisations, les utilisateurs peuvent également aider à promouvoir les tutoriels qu'ils ont utilisés et à encourager d'autres personnes à essayer ces projets créatifs.",
        status: "warning",
    }
]
