import React from 'react';
import {Box, Container, Divider, Paper, Stack, styled, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const Logo = styled("img")(({theme}) => ({
    transform: 'rotate(-90deg)',
    marginBottom:"10%",
    [theme.breakpoints.up('xs')]: {
        width: "70%",
    },
    [theme.breakpoints.up('sm')]: {
        width: "40%",
    },
    [theme.breakpoints.up('md')]: {
        width: "30%",
    },
    [theme.breakpoints.up('lg')]: {
        width: "43%",
    },
}));

const Main = () => {
    return (
        <Stack justifyContent="center" alignItems="center" textAlign="center" spacing={{xs: 5, md: 5}} sx={{
            minHeight: '83vh',
            background: 'linear-gradient(45deg, rgba(85, 206, 248, 0.5) 0%, rgba(157, 98, 222, 0.5) 100%)',
            color:"white"
        }}>
            <Container>
                <Grid container spacing={15} justifyContent="center" mt={{xs:2}}>
                    <Grid xs={12} md={6}>
                        <Logo src={`${process.env.PUBLIC_URL}/logo/logo_white.svg`} alt="logo"/>
                        <Stack sx={{display: {xs: 'none', md: 'flex'}}}>
                            <Typography paragraph variant="h2">TUTOTOU</Typography>
                            <Typography paragraph variant="h3">Des tutos de tout !</Typography>
                            <Typography paragraph variant="h4">La plateforme de tutoriel étape par étape</Typography>
                        </Stack>
                        <Stack sx={{display: {xs: 'flex', md: 'none'}}}>
                            <Typography sx={{fontFamily: 'roboto'}} paragraph variant="h3">TUTOTOU</Typography>
                            <Typography sx={{fontFamily: 'roboto'}} paragraph variant="h4">Des tutos de tout
                                !</Typography>
                            <Typography sx={{fontFamily: 'roboto'}} paragraph variant="h5">La plateforme de tutoriel
                                étape
                                par étape</Typography>
                        </Stack>
                    </Grid>
                    <Grid xs={12} md={6} textAlign="start">
                        <Box sx={{background: "rgba(40, 42, 53, .3)", p:2, borderRadius:5}}>
                        <Typography variant="h4">Comment ça marche :</Typography>
                        <br/>
                        <Typography variant="body1" paragraph sx={{textAlign: "justify"}}>
                            Le mode "étape par étape" de Tutotou permet aux utilisateurs de suivre un tutoriel de
                            manière
                            progressive et détaillée. Chaque page présente une ou plusieurs étape, ce qui facilite la
                            compréhension et évite de perdre le fil du tutoriel. À chaque étape, l'utilisateur peut lire
                            les
                            instructions, voir des images et même regarder des vidéos explicatives si le créateur en a
                            ajouté.
                            Une fois qu'une étape est terminée, l'utilisateur peut passer à la suivante en cliquant sur
                            un
                            bouton, et ainsi de suite jusqu'à la fin du tutoriel. Cela rend l'apprentissage beaucoup
                            plus
                            facile
                            et agréable !

                            L'avantage du mode "étape par étape" est qu'il permet de rendre les tâches complexes plus
                            faciles à
                            suivre et à comprendre, en les décomposant en étapes claires et concises. Cela peut être
                            particulièrement utile pour les personnes qui apprennent mieux en visualisant les étapes ou
                            qui
                            ont
                            besoin d'instructions pas à pas pour accomplir une tâche avec succès.
                        </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Stack>
    )
        ;
};

export default Main;
